import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { styled } from "../../../stitches.config";
import NoResultsIcon from "../../images/news/no-results.svg";

export const NoResults = () => {
  const { t } = useTranslation();

  return (
    <NoResultsWrap>
      <ImageWrap>
        <img src={NoResultsIcon} />
      </ImageWrap>
      <NoResultsText>{t("noResults")}</NoResultsText>
    </NoResultsWrap>
  );
};

const NoResultsWrap = styled("div", {
  textAlign: "center",
});

const NoResultsText = styled("span", {
  display: "block",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "24px",
  color: "$text",
  margin: "0 auto",
});

const ImageWrap = styled("div", {
  maxWidth: 248,
  maxHeight: 231,
  margin: "32px auto 24px auto",
  "& > img": {
    width: "158px ",
    height: "148px ",
    "@md": {
      width: "231px ",
      height: "248px ",
    },
  },
  "@md": {
    margin: "112px auto 42px auto",
  },
});
