import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import { styled } from "../../../stitches.config";
import { ArticlesList } from "../../components/News/ArticlesList";
import { BreadCrumbs } from "../../components/shared/BreadCrumbs";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Layout } from "../../layout";
import { routes } from "../../routes/routes";
import { getPageSize } from "../../utils";
import { Article, Category } from "../../types";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { useScreenResolution } from "../../hooks/useScreenResolution";
import { Pagination } from "../../components/News/Pagination";
import { Loader } from "../../components/shared/Loader";
import { Button } from "../../components/shared/Button";
import { NewsSearchInput } from "../../components/News/NewsSearchInput";
import { CategoriesMobileMenuTrigger } from "../../components/News/CategoriesMobileMenuTrigger";
import { CategoriesDesktop } from "../../components/News/CategoriesDesktop";
import { usePrevious } from "../../hooks/usePrevious";
import { NoResults } from "../../components/News/NoResults";
import { isBrowser } from "../../components/shared/LiveSupport";
import { useScrollToElement } from "../../hooks/useScrollToElement";

export const NewsSearchView = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [articles, setArticles] = useState<Article[]>([]);
  const [latestArticles, setLatestArticles] = useState<Article[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [page, setPage] = useState({ currentPage: 1, totalPages: 1 });
  const [load, setLoad] = useState({
    isLoading: false,
    finished: false,
  });
  const resolution = useScreenResolution();
  const query = isBrowser() && window.location.search.replace("?q=", "");
  const pageSize = getPageSize(resolution.resolution);
  const prevQuery = usePrevious({ query });
  const { ref, scrollToElement } = useScrollToElement({ desktopOnly: true });

  useEffect(() => {
    const getArticlesForQuery = async () => {
      setLoad((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { data } = await sendRequestToAPI(
          ` query($locale: I18NLocaleCode, $page: Int, $pageSize: Int, $query: String){
                articles(locale: $locale, filters: {or: [{title: {
          containsi: $query
         }}, {description:{containsi: $query }}]}, 
            pagination:{pageSize: $pageSize, page: $page}, sort:"publicationDate:desc"){
                meta{pagination{
                     total
                     page
                     pageSize
                     pageCount}}
                     data{
                 attributes{
                   previewImage{data{attributes{url}}}
                   title
                   publicationDate
                   slug
                   category{
                     data{
                       attributes{
                         categoryId
                         categoryName
                       }
                     }
                   }
                   description
                 }
                }}
             }`,
          {
            query,
            locale: language,
            pageSize,
            page: page.currentPage,
          }
        );
        setLoad((prevState) => {
          const finished =
            page.currentPage === data.data.articles.meta.pagination.pageCount
              ? { finished: true }
              : {};
          return { ...prevState, isLoading: false, ...finished };
        });
        setPage((prevState) => ({
          ...prevState,
          totalPages: data.data.articles.meta.pagination.pageCount,
        }));
        setArticles((prevState) => [
          ...(resolution.resolution === "desktop"
            ? [...data.data.articles.data]
            : [...prevState, ...data.data.articles.data]),
        ]);
      } catch (err) {
        return err;
      }
    };
    if (prevQuery && prevQuery.query !== query) {
      setPage(() => ({ currentPage: 1, totalPages: 1 }));
      resolution.resolution === "mobile" && setArticles([]);
    }

    resolution.resolution && pageSize && getArticlesForQuery();
  }, [query, page.currentPage, pageSize]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await sendRequestToAPI(
          `
           query($locale: I18NLocaleCode){
             newsCategories(locale: $locale,filters:{articles:{slug: {notNull: true}}}, sort:"categoryName:asc"){
                  data{
                    attributes{
                      categoryId
                      categoryName
                    }
                  }
                }
              latestArticles: articles(locale: $locale, pagination:{limit: 3}, sort:"publicationDate:desc"){  
              meta{pagination{
              total
              page
              pageSize
              pageCount}}
              data{attributes{  
              publicationDate
              description
              title
              slug
              previewImage{
                data{attributes{url}}
              }
              image{
                data{attributes{url}}
              }
              category{
                data{attributes{categoryName
                categoryId}}
              }
            }}
          }
           }
        `
        );
        setLatestArticles(data.data.latestArticles.data);
        setCategories(data.data.newsCategories.data);
      } catch (err) {
        return err;
      }
    };
    getData();
  }, []);

  return (
    <Layout>
      <ArticlesSection>
        <NavStripe ref={(interalRef) => (ref.current = interalRef)}>
          <NavStripeContent>
            <BreadCrumbsWrapper>
              <BreadCrumbs
                color="black"
                showOnMobile={true}
                root={{ link: routes.news.news, title: t("newsHeading") }}
                current={t("search")}
              />
            </BreadCrumbsWrapper>
            <Heading>{t("search")}</Heading>
          </NavStripeContent>
        </NavStripe>
        <ContentContainer>
          <CategoriesWrap>
            <CategoriesDesktop
              categories={categories}
              latestArticles={latestArticles}
            />
          </CategoriesWrap>
          <NewsContainer>
            <SearchWrap>
              <SearchText>{t("searchResults")}</SearchText>
              <InputWrap>
                <NewsSearchInput />
                <CategoriesMobileMenuTrigger categories={categories} />
              </InputWrap>
              {!!query && !!articles.length && (
                <Remark>{t("ifNotFound")}</Remark>
              )}
            </SearchWrap>
            {!load.isLoading ? (
              <>
                {!!query && !articles.length ? (
                  <NoResults />
                ) : (
                  <>
                    <ArticlesList articles={articles} />
                    {!!articles.length && (
                      <PaginationWrap>
                        {!load.finished &&
                          (load.isLoading ? (
                            <Loader />
                          ) : (
                            <Button
                              css={{
                                "@md": {
                                  display: "none",
                                },
                              }}
                              onClick={() =>
                                setPage((prevState) => ({
                                  ...prevState,
                                  currentPage: prevState.currentPage + 1,
                                }))
                              }
                            >
                              {t("loadMore")}
                            </Button>
                          ))}
                        <Pagination
                          currentPage={page.currentPage}
                          totalPages={page.totalPages}
                          increase={() => {
                            setPage((prevState) => ({
                              ...prevState,
                              currentPage: prevState.currentPage + 1,
                            }));
                            scrollToElement();
                          }}
                          decrease={() => {
                            setPage((prevState) => ({
                              ...prevState,
                              currentPage: prevState.currentPage - 1,
                            }));
                            scrollToElement();
                          }}
                        />
                      </PaginationWrap>
                    )}
                  </>
                )}
              </>
            ) : (
              <Loader size={"xl"} />
            )}
          </NewsContainer>
        </ContentContainer>
      </ArticlesSection>
    </Layout>
  );
};

const ArticlesSection = styled("section", {});

const NavStripe = styled("div", {
  backgroundColor: "$white",
  "@md": {
    backgroundColor: "$primaryBackground",
    padding: "40px 0px",
  },
});

const NavStripeContent = styled("div", {
  maxWidth: 1280,
  display: "flex",
  flexDirection: "column-reverse",
  "@md": {
    margin: "0 auto",
    flexDirection: "column",
  },
});

const Heading = styled("h1", {
  color: "$heading",
  fontSize: "32px",
  lineHeight: "40px",
  textAlign: "center",
  margin: "0px 0px 24px 0px",
  padding: "40px 16px",
  "@md": {
    margin: "0px",
    padding: 0,
    textAlign: "start",
    fontSize: "48px",
    lineHeight: "56px",
  },
});

const BreadCrumbsWrapper = styled("div", {
  marginLeft: "16px",
  "@md": {
    marginLeft: 0,
  },
});

const ContentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "6px 16px 80px 16px",
  "@md": {
    flexDirection: "row-reverse",
    padding: "80px 0px 100px 0px",
    maxWidth: 1280,
    margin: "0 auto",
  },
});

const NewsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  "@md": { maxWidth: "890px" },
});

const PaginationWrap = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "16px",
  "@md": {
    marginTop: "36px",
  },
});

const SearchWrap = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  alignItems: "center",
});

const InputWrap = styled("div", {
  display: "flex",
  flexBasis: "100%",
  "@md": { flexBasis: "calc(100% - 238px)" },
});

const SearchText = styled("span", {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "$heading",
  flexBasis: "100%",
  margin: "0px 0px 24px 0px",
  "@md": {
    flexBasis: "230px",
    margin: "0px 8px 0px 0px",
  },
});

const Remark = styled("span", {
  margin: "8px 0px 0px 0px",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
  color: "$screedDisplayText",
  flexBasis: "100%",
  "@md": {
    fontSize: "15px",
    margin: "18px 0px 0px 0px",
  },
});

const CategoriesWrap = styled("div", {
  display: "none",
  "@md": {
    display: "block",
    marginLeft: "80px",
    flexBasis: "301px",
  },
});
