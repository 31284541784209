import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { styled } from "../../../stitches.config";
import SearchIcon from "../../../static/icons/common/search.svg";
import Close from "../../../static/icons/common/close.svg";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { isBrowser } from "../shared/LiveSupport";

export const NewsSearchInput = () => {
  const defaultValue =
    isBrowser() &&
    decodeURI(window.location.search).replace("?q=", "").replaceAll("_", " ");
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const [value, setValue] = useState(defaultValue ? defaultValue : "");

  const changeQuery = () => {
    navigate(`/news/search?q=${value.replaceAll(" ", "_")}`);
  };

  useEffect(() => {
    const timeout = setTimeout(changeQuery, 300);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <InputWrapper>
      {!value && <Placeholder>{t("search")}</Placeholder>}
      <IconWrap>
        <SearchIcon />
      </IconWrap>
      <IconWrap visible={!!value} onClick={() => setValue("")} type={"close"}>
        <Close />
      </IconWrap>
      <Input value={value} onChange={(e) => setValue(e.target.value)} />
    </InputWrapper>
  );
};

const InputWrapper = styled("div", {
  border: "1px solid $inputColor",
  borderRadius: "200px",
  position: "relative",
  height: "48px",
  flexGrow: 2,
  marginBottom: 0,
  "@md": {
    padding: "12px",
    width: "100%",
  },
});
const Input = styled("input", {
  width: "100%",
  height: "100%",
  padding: "12px 48px 12px 46px",
  border: "none",
  fontWeight: 400,
  lineHeight: "24px",
  fontSize: "17px",
  color: "$text",
  borderRadius: "200px",
  "@md": {
    padding: "12px 48px 12px 12px",
  },
});
const IconWrap = styled("span", {
  position: "absolute",
  fill: "$gray",
  top: 2,
  transition: "all ease-in-out 300ms",
  left: 2,
  padding: "10px",
  height: "100%",
  "@md": {
    display: "none",
  },
  variants: {
    visible: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
        pointerEvents: "none",
      },
    },
    type: {
      close: {
        top: 0,
        left: "unset",
        right: 2,
        cursor: "pointer",
        "&:hover": {
          "& > svg": {
            fill: "$accent",
          },
        },
        "@md": {
          display: "block",
        },
      },
    },
  },
});

const Placeholder = styled("span", {
  position: "absolute",
  color: "$gray",
  fontWeight: 400,
  lineHeight: "24px",
  fontSize: "17px",
  top: 11,
  left: 46,
  pointerEvents: "none",
  "@md": {
    padding: "12px",
    top: 0,
    left: 12,
  },
});
